<template>
  <rpm-layout>
    <div class="bg-white rounded-lg p-2">
      <p class="text-gray-700 text-2xl font-bold mb-2">Reports</p>
      <div class="flex gap-2 items-center mb-2">
        <el-select v-model="category" style="min-width: 250px;">
          <el-option value="rpm" label="Monthly RPM Patient Report"></el-option>
          <el-option value="ccm" label="Monthly CCM Patient Report"></el-option>
        </el-select>
        <el-select v-model="month" id="month" filterable>
          <el-option v-for="(name, key) in {1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'}" :value="key" :key="key" :label="name"></el-option>
        </el-select>
        <el-select v-model="year" id="year" filterable>
          <el-option value="2020" label="2020"></el-option>
          <el-option value="2021" label="2021"></el-option>
          <el-option value="2022" label="2022"></el-option>
          <el-option value="2023" label="2023"></el-option>
          <el-option value="2024" label="2024"></el-option>
        </el-select>
        <el-select v-model="client" id="client" placeholder="Client" filterable>
          <el-option v-for="client in this.availableClients" :value="client.id" :key="client.id" :label="client.name"></el-option>
        </el-select>
      </div>
      <div class="flex justify-between items-center">
        <div>
          <el-input placeholder="Search" v-model="search" />
        </div>
        <div class="mb-2">
          <a v-if="tableDataFiltered.length && !(year > new Date().getFullYear() || (year == new Date().getFullYear() && month >= new Date().getMonth() + 1))" target="_blank" :href="getCombinedPdfLink()" class="inline-block px-4 py-2">Download Combined PDF</a>
        </div>
        <!--
        <div>
          <a target="_blank" :download="`${this.clientName}-${this.year}-${this.month}-billing-report.csv`" :href="csvLink()" class="m-2 py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200" v-if="tableDataFiltered.length">Download Report CSV</a>
        </div>
        -->
      </div>
      <el-table
          v-loading="loading"
          size="small"
          :fit="true"
          stripe
          :data="displayData"
          style="width: 100%"
          :default-sort="{prop: 'Patient Name', order: 'ascending'}"
          :empty-text="!client ? 'Please select a client first.' : 'No data.'"
          >
        <el-table-column sortable fixed min-width="120" prop="Patient Name" label="Patient Name">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>Name: {{ scope.row['Patient Name'] }}</p>
              <el-tag class="cursor-pointer mt-2" @click="goToPatient(scope.row.id)">Details</el-tag>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row['First Name'] }}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="110" prop="First Name" label="First Name"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="110" prop="Last Name" label="Last Name"></el-table-column>
        <el-table-column sortable min-width="120" prop="Middle Initial" label="Middle Initial"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="Street 1" label="Street 1"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="Street 2" label="Street 2"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="City" label="City"></el-table-column>
        <el-table-column sortable min-width="100" prop="State" label="State"></el-table-column>
        <el-table-column sortable min-width="100" prop="Zip Code" label="Zip Code"></el-table-column>
        <el-table-column sortable min-width="110" prop="Phone 1" label="Phone 1"></el-table-column>
        <el-table-column sortable min-width="110" prop="Phone 3" label="Phone 3"></el-table-column>
        <el-table-column sortable min-width="120" :formatter="dateFormatter" prop="Date of Birth" label="Date of Birth"></el-table-column>
        <el-table-column sortable min-width="100" prop="Sex" label="Sex"></el-table-column>
        <el-table-column sortable min-width="120" prop="Soc Sec Num" label="Soc Sec Num"></el-table-column>
        <el-table-column sortable min-width="130" prop="Marital Status" label="Marital Status"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="120" prop="Plan Name" label="Plan Name"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="120" prop="Subscriber ID" label="Subscriber ID"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="Group No" label="Group No"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="Secondary Plan Name" label="Secondary Plan Name"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="190" prop="Secondary Subscriber ID" label="Secondary Subscriber ID"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="160" prop="Secondary Group No" label="Secondary Group No"></el-table-column>
        <el-table-column sortable min-width="170" prop="Insured Authorization" label="Insured Authorization"></el-table-column>
        <el-table-column sortable min-width="150" prop="Signature on File" label="Signature on File"></el-table-column>
        <el-table-column sortable min-width="100" :formatter="dateFormatter" prop="SOF Date" label="SOF Date"></el-table-column><!-- this sorting method is ok -->
        <el-table-column show-overflow-tooltip sortable min-width="100" prop="Email" label="Email"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="120" prop="Facility Name" label="Facility Name"></el-table-column>
        <el-table-column sortable min-width="120" :formatter="dateFormatter" prop="Billing Month" label="Billing Month"></el-table-column><!-- but this method doesn't require special sorting since the underlying data is ordered already -->
        <el-table-column show-overflow-tooltip sortable min-width="150" prop="Ordering Physician" label="Ordering Physician"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="150" prop="Health Coach" label="Health Coach"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="Health Condition (DX)" label="Health Condition (DX)"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="ICD Codes" label="ICD Codes"></el-table-column>

        <el-table-column show-overflow-tooltip sortable min-width="170" :formatter="dateFormatter" prop="Care Plan Start Date" label="Care Plan Start Date"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="Days in Billing Cycle" label="Days in Billing Cycle"></el-table-column>
        <el-table-column show-overflow-tooltip sortable min-width="170" prop="Days From Start Date" label="Days From Start Date"></el-table-column>

        <el-table-column sortable min-width="150" prop="Total Review Time (minutes)" label="Total Review Time"></el-table-column>

        <el-table-column v-if="category === 'ccm'" sortable min-width="130" prop="Physician Time" label="Physician Time"></el-table-column>
        <el-table-column v-if="category === 'ccm'" sortable min-width="160" prop="Non-Physician Time" label="Non-Physician Time"></el-table-column>

        <el-table-column v-if="category === 'ccm'" sortable min-width="80" prop="CCM CPT 99490" label="99490"></el-table-column>
        <el-table-column v-if="category === 'ccm'" sortable min-width="80" prop="CCM CPT 99439" label="99439"></el-table-column>
        <el-table-column v-if="category === 'ccm'" sortable min-width="80" prop="CCM CPT 99491" label="99491"></el-table-column>
        <el-table-column v-if="category === 'ccm'" sortable min-width="80" prop="CCM CPT 99437" label="99437"></el-table-column>

        <el-table-column v-if="category === 'rpm'" sortable min-width="80" prop="RPM CPT 99453" label="99453"></el-table-column>
        <el-table-column v-if="category === 'rpm'" sortable min-width="80" prop="RPM CPT 99454" label="99454"></el-table-column>
        <el-table-column v-if="category === 'rpm'" sortable min-width="80" prop="RPM CPT 99457" label="99457"></el-table-column>
        <el-table-column v-if="category === 'rpm'" sortable min-width="80" prop="RPM CPT 99458" label="99458"></el-table-column>

        <el-table-column fixed="right" min-width="120" label="Actions" align="center">
          <template slot-scope="scope">
            <button @click="downloadReport(scope.row)" :disabled="downloadReportPatient" class="text-blue-500 bg-transparent ring-1 ring-blue-500 hover:text-white hover:bg-blue-500 rounded text-sm my-0.5 px-2">
              <svg v-if="downloadReportPatient && downloadReportPatient.id===scope.row.id" height="10px" aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
              </svg>
              <font-awesome-icon v-else icon="file-download" />
              <span class="text-xs pl-1">Download</span>
            </button>
          </template>
        </el-table-column>

        <el-table-column sortable :formatter="dateFormatter" min-width="110" prop="start" label="Billing Start"></el-table-column>
        <el-table-column sortable :formatter="dateFormatter" min-width="110" prop="end" label="Billing End"></el-table-column>
      </el-table>

      <el-divider></el-divider>

      <div style="text-align: center">
        <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :total="tableDataFiltered.length">
        </el-pagination>
      </div>

      <div v-if="downloadReportPatient">
        <patient-report :patient="downloadReportPatient" :category="category" v-on:downloaded="downloadReport(null)"/>
      </div>
    </div>

  </rpm-layout>
</template>


<style>
body {
  margin: 0;
}
</style>

<script>
import RpmLayout from '../../layouts/RpmLayout.vue';
import PatientReport from './PatientReport.vue';
import moment from "moment-timezone";
import Vue from "vue";
import PatientMixin from "@/mixins/Patient";


export default {
  mixins: [PatientMixin],
  components: {RpmLayout, PatientReport},
  data() {
    return {
      search: '',
      client: null,
      category: 'rpm',
      year: moment().format("YYYY"),
      month: moment().format("M"),
      tableData: [],
      token: null,
      loading: true,
      downloadReportPatient: null,

      page: 1,
      pageSize: 10
    };
  },
  created: async function () {
    this.token = await this.$auth.getTokenSilently();
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser') : null,
      !this.$store.state.patients.patients.length ? this.$store.dispatch('getPatients') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
    ]).finally(() => this.loading = false);
  },
  mounted: function () {
    // this.setRange();
  },
  computed: {
    clientName() {
      if (!this.client) return '';
      return this.availableClients.find((c) => c.id == this.client).name;
    },
    tableDataFiltered() {
      if (!this.tableData || this.tableData.length === 0) return [];
      if (this.search === '') {
        return this.tableData;
      }
      return this.tableData.filter(r => Object.values(r).filter(val => String(val).toLowerCase().includes(this.search.toLowerCase())).length);
    },
    availableClients() {
      return this.$store.state.patients.authUser.available_clients;
    },
    displayData() {
      if (!this.tableDataFiltered || this.tableDataFiltered.length === 0) return [];
      return this.tableDataFiltered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    },
    arrayToCSV() {
      if (!this.tableDataFiltered.length){
        return '';
      }
      const escapeField = field => `"${(''+field).replace(/"/g, '""').replace(/\n/g, '\\n')}"`;

      const csvHeaders = Object.keys(this.tableDataFiltered[0]).map(escapeField).join(',');
      const csvRows = this.tableDataFiltered.map(row => Object.values(row).map(escapeField).join(',')).join('\n');

      return `${csvHeaders}\n${csvRows}`;
    },

  },
  watch: {
    year() {
      // this.setRange();
      this.getData();
    },
    month() {
      // this.setRange();
      this.getData();
    },
    category() {
      this.getData();
    },
    client() {
      this.getData();
    },
  },
  methods: {
    dateTimeFormatter(row, column, cellValue, index) {
      return cellValue ? moment.utc(cellValue).local().format("MMM D, YYYY h:mm a") : '';
    },
    dateFormatter(row, column, cellValue, index) {
      return cellValue ? moment(cellValue).format("MMM D, YYYY") : '';
    },
    dateSort(a,b) {
      return moment(a).valueOf() - moment(b).valueOf();
    },
    // setRange() {
    //   this.startDate = moment({year: this.year, month: this.month - 1}).endOf('month').format('YYYY-MM-DD');
    //   this.endDate = moment({year: this.year, month: this.month - 1}).endOf('month').add(15, 'days').format('YYYY-MM-DD');
    // },
    csvLink() {
      const file = new File([this.arrayToCSV], 'report.csv', {type: 'text/csv',});
      return URL.createObjectURL(file);
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    getCombinedPdfLink() {
      return `${process.env.VUE_APP_API_URL || 'http://localhost:8000/'}api/rpm_ccm_dashboard/combined-pdf?token=${this.token}&client=${this.client}&month=${this.month}&year=${this.year}`;
    },
    async getData() {
      if (!(this.client && this.category)) {
        return;
      }
      this.loading = true;
      return Vue.$http
          .get("/api/rpm_ccm_dashboard/billing-data", {
            params: {
              token: await this.token,
              category: this.category,
              client: this.client,
              month: this.month,
              year: this.year,
            },
          })
          .then((res) => (this.tableData = res.data.data))
          .catch((error) => this.$awn.warning(error.response.exception))
          .finally(() => {
            this.loading = false;
        });
    },
    downloadReport(patient) {
      this.downloadReportPatient = patient;
    },
  },
};
</script>
